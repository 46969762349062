import queryString from 'query-string';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from 'shared/constants/AppConst';
import AppContextPropsType from 'types/AppContextPropsType';

import purchaseAPI from '@healthme/services/api/purchases/Index';
import cartService from '@healthme/services/cart/index';
import patientService from '@healthme/services/patient/index';
import AppContext from '@healthme/utility/AppContext';

import { cacheApi2 } from '../../../utility/Utils';

const pathArr = window.location.pathname.split('/').filter(e => e.length);
const orgSlug = pathArr[0];
const marketplaceSlug = pathArr[1];

export default function Initializer() {
	const location = useLocation();
	const { updatePrimaryColor, updateImageLogo, setPageLoading } =
		useContext<AppContextPropsType>(AppContext);
	React.useEffect(() => {
		setPageLoading(true);
		const qs = queryString.parse(location.search);
		if (qs?.t) {
			localStorage.setItem('paylink', qs.t);
		} else {
			console.log('no paylink');
			localStorage.removeItem('paylink');
		}
		if (qs?.reinit && qs?.reinit === 'healthme') {
			sessionStorage.clear();
		}
	}, []);
	useQuery(
		`organization:${orgSlug}`,
		() => cacheApi2(`public/organizations/${orgSlug}`, {}, orgSlug),
		{
			onSuccess: data => {
				patientService.setPatientOrganization(data);
				localStorage.setItem('orgSlug', orgSlug);
				if (!marketplaceSlug || marketplaceSlug === 'paylink') {
					sessionStorage.clear();

					let queryParams = {
						organization_id: data.id,
					};

					cacheApi2(
						`public/marketplaces`,
						{ queryParams },
						`initializer-${data.id}`
					).then(resp => {
						if (resp.data.length) {
							if (marketplaceSlug === 'paylink') {
								const marketplace = resp.data[0];
								const qs = queryString.parse(location.search);
								const paylink = qs?.t;
								purchaseAPI
									.getRaw(`paylinks/token`, {
										queryParams: { t: paylink },
									})
									.then(resp => {
										if (typeof resp === 'string') {
											setPageLoading(false);
											window.location.href = `/${orgSlug}/${marketplace.name_slug}/expired`;
											return;
										}
										localStorage.setItem(
											'paylinkPayload',
											JSON.stringify(resp)
										);
										localStorage.setItem(
											'marketplaceSlug',
											marketplace.name_slug
										);
										cartService.clear();
										window.location.href = `/${orgSlug}/${marketplace.name_slug}/checkout?t=${paylink}`;
									})
									.catch(e => {
										window.location.href = `/${orgSlug}/404`;
									});
								return;
							}
						}
						window.location.href = `/${orgSlug}/${resp.data[0].name_slug}`;
						return;
					});
				}

				let key = `initializer-${marketplaceSlug}`;
				cacheApi2(`public/marketplaces/${marketplaceSlug}`, {}, key).then(marketplace => {
					if (updatePrimaryColor)
						updatePrimaryColor('#' + marketplace.primary_color.replace('#', ''));
					if (updateImageLogo && marketplace.image_path)
						updateImageLogo(`${BASE_URL}/${marketplace.image_path}`);
					patientService.set('marketplaces', JSON.stringify(marketplace), 'marketplace');
					localStorage.setItem('marketplaceSlug', marketplaceSlug);
					patientService.setPatientMarketplace(marketplace);
					setPageLoading(false);
				});
			},
		}
	);

	return <></>;
}
