import 'react-bootstrap-typeahead/css/Typeahead.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { BASE_URL } from 'shared/constants/AppConst';

import Icon from '@healthme/core/Icon/Index';
import { AsyncTypeahead } from '@healthme/core/react-bootstrap-typeahead'; // ES2015

let CACHE = {};

const appAxios = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});

appAxios.interceptors.request.use(
	config => {
		let headers = config.headers;
		config.headers = {
			...headers,
			...getHeaders(),
		};
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getToken
 **-------------------------------------------------------------------------------------
 */
const getHeaders = () => {
	const headers = {
		'Content-Type': 'application/json',
	};
	const organization = localStorage.getItem('organization');
	const isLoggedIn = localStorage.getItem('loggedIn');
	const assumeRole = localStorage.getItem('assumeRole');
	const networkOrganization = localStorage.getItem('networkOrg');
	if (organization && isLoggedIn) {
		headers['Assume-Organization'] = `${organization}`;
	}
	if (assumeRole && isLoggedIn) {
		headers['Assume-Role'] = `${assumeRole}`;
	}

	if (isLoggedIn && networkOrganization) {
		headers['Assume-Network-Organization'] = `${networkOrganization}`;
	}


	return headers;
};

interface Props {
	onChange: (val: string, data?: any) => void;
	selectedValue?: string | string[];
	multiple?: boolean;
	error?: string;
	name: string;
	title?: string;
	disabled?: boolean;
	path?: string | (() => string);
	term2?: string;
	term?: string;
	init?: boolean;
	formatter?: (arr) => any;
	clear?: number;
	extraParams?: string;
	className?: string;
	orderBy?: string;
	dropup?: Boolean;
	useCache?: Boolean;

	alterSelectedLabel?: (item) => string;
	cacheKey?: string;
	sendHeaders?: boolean;
	overridePath?: string;
}

const AsyncSelectField = ({
	onChange,
	selectedValue = '',
	multiple = false,
	name = '',
	error = '',
	disabled = false,
	term = 'name',
	title = '',
	path = '',
	init = true,
	formatter = arr => ({ value: arr.id, label: arr.name }),
	clear = 0,
	extraParams = '',
	className = '',
	term2 = '',
	dropup = false,
	useCache = false,
	cacheKey = '',
	sendHeaders = true,
	overridePath = '',
	alterSelectedLabel = item => item?.label,
}: Props) => {
	const PER_PAGE = 25;
	const [placeholder, setPlaceholder] = useState(title);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [query, setQuery] = useState('');
	const [apiPath, setApiPath] = useState(typeof path === 'function' ? path() : path);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<any>([]);
	const [prevValue, setPrevValue] = useState<any>([]);

	useEffect(() => {
		if (!init || !selectedValue || !selectedValue.length) return;
		let valueArray = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
		if (useCache) {
			setSelected(JSON.parse(localStorage.getItem(`${cacheKey}__input_${name}`) || '[]'));
			return;
		}

		const vals = valueArray.map(async (val, i) => {
			const resp: any = await apiRequest({ initialValue: val });
			if (name == 'package_type') {
				let v = Array.isArray(selectedValue) ? selectedValue[i] : selectedValue;
				return {
					value: v,
					label: (v as string).replace(/_/gi, ' '),
				};
			}
			return {
				...resp.data,
				value: resp.data.id,
				label: `${resp.data[term]}${term2.length > 0 ? ', ' + resp.data[term2] : ''}`,
			};
		});
		Promise.all(vals).then(resp => {
			setSelected(
				resp.map(e => ({
					...e,
					label: e?.label ? e.label.replace(/_/gi, ' ') : '',
				}))
			);
		});
	}, [selectedValue]);

	useEffect(() => {
		if (clear) {
			CACHE = {};
			localStorage.removeItem(`${cacheKey}__input_${name}`);
			setOptions([]);
			setSelected([]);
			handleSearch();
		}
	}, [clear]);

	useEffect(() => {
		setApiPath(path);
	}, [path]);
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - apiRequest
	 **-------------------------------------------------------------------------------------
	 */
	const apiRequest = ({ page = 1, query = '', initialValue = '' }) => {
		let url = `${BASE_URL}/${apiPath}?per_page=${PER_PAGE}&page=${page}${extraParams}&order_by=+${term}`;

		if (query.length && query != '__') {
			url = `${BASE_URL}/${apiPath}?per_page=${PER_PAGE}&${term}[ilike]=${query}${extraParams}&order_by=+${term}`;
		}
		if (initialValue.length) {
			url = `${BASE_URL}/${apiPath}/${initialValue}?${extraParams}&order_by=+${term}`;
		}
		if (initialValue.length && overridePath.length) {
			url = `${BASE_URL}/${overridePath}/${initialValue}?${extraParams}&order_by=+${term}`;
		}
		if (name == 'package_type' || name == 'packageFilter') {
			url = `${BASE_URL}/${apiPath}`;
		}
		return appAxios.get(url, {
			withCredentials: true,
		});
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getData
	 **-------------------------------------------------------------------------------------
	 */
	const getData = (query = '', page = 1) => {
		return new Promise(resolve => {
			apiRequest({ query, page }).then((resp: any) => {
				const data = Array.isArray(resp.data) ? resp.data : resp.data.data;
				const options = data.map(formatter);
				resolve({ options, total_count: resp?.data?.page_meta?.total || data.length });
			});
		});
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - handleSearch
	 **-------------------------------------------------------------------------------------
	 */
	const handleSearch = (q = '__') => {
		if (CACHE[`${name}_${q}`] && q !== '__') {
			setOptions(removeDups(CACHE[`${name}_${q}`].options));
			return;
		}
		setIsLoading(true);
		getData(q).then((resp: any) => {
			CACHE[`${name}_${q}`] = { ...resp, page: 1 };
			setIsLoading(false);
			setOptions(removeDups(resp.options));
		});
	};
	const removeDups = arr =>
		arr.filter((v, i, a) => a.findIndex(v2 => v2.value === v.value) === i);
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - handlePagination
	 **-------------------------------------------------------------------------------------
	 */
	const handlePagination = (e, shownResults) => {
		const key = query ? `${name}_${query}` : `${name}___`;
		const cachedQuery = CACHE[key];
		if (
			(cachedQuery && cachedQuery.options.length > shownResults) ||
			(cachedQuery && cachedQuery.options.length === cachedQuery.total_count)
		) {
			return;
		}
		setIsLoading(true);
		const page = cachedQuery?.page + 1;
		getData(query, page).then((resp: any) => {
			const options = cachedQuery.options.concat(resp.options);
			CACHE[key] = { ...cachedQuery, options, page };
			setIsLoading(false);
			setOptions(removeDups(options));
		});
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - handleInputChange
	 **-------------------------------------------------------------------------------------
	 */
	const handleInputChange = q => {
		setQuery(q);
		handleSearch(q);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - filterBy
	 **-------------------------------------------------------------------------------------
	 */
	const filterBy = () => true;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeDropDown
	 **-------------------------------------------------------------------------------------
	 */
	const closeDropDown = () => {
		//setOpen(false);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - Return
	 **-------------------------------------------------------------------------------------
	 */
	return (
		<div
			className="relative mb-0 floating-label"
			onBlur={() => setOpen(false)}
			onMouseLeave={closeDropDown}
		>
			<div className={`floating-label select-container w-full mb-0 ${className}`}>
				<AsyncTypeahead
					open={open}
					filterBy={filterBy}
					id={`__input_${name}`}
					key={`__input_${name}`}
					className={`normal-typeahead __input_${name} ${
						error.length && 'error capitolize'
					}`}
					onMenuToggle={setOpen}
					minLength={0}
					maxResults={PER_PAGE - 1}
					onPaginate={handlePagination}
					placeholder={placeholder}
					onInputChange={handleInputChange}
					paginate
					isLoading={isLoading}
					labelKey="label"
					useCache={false}
					dropup={dropup ? true : false}
					onSearch={handleSearch}
					options={options}
					selected={selected}
					multiple={multiple}
					disabled={disabled}
					clearButton={true}
					onFocus={e => {
						handleSearch();
						setOpen(true);
						setPlaceholder('Start typing to search');
					}}
					onBlur={e => {
						setPlaceholder(title);
					}}
					onChange={(selected: any) => {
						if (!selected.length) {
							setSelected([]);
							onChange('', {});
							return;
						}
						selected[0].label = alterSelectedLabel(selected[0]);
						setSelected(selected);
						if (useCache)
							localStorage.setItem(
								`${cacheKey}__input_${name}`,
								JSON.stringify(selected)
							);
						setPrevValue(selected);
						if (selected.length && !multiple) {
							onChange(selected[0]['value'], selected[0]);
						}
						if (selected.length && multiple) {
							onChange(
								selected.map(i => i.value),
								selected
							);
						}
						if (!selected.length) {
							onChange('', {});
						}
						setOpen(false);
					}}
					renderMenuItemChildren={(option: any) => {
						let isSelected = false;
						if (selected.length && option.value === selected[0].value)
							isSelected = true;
						return (
							<div
								key={option.value}
								className={`select-option-type  ${
									isSelected ? 'selected' : 'not-selected'
								}`}
							>
								<div className="flex items-center justify-between">
									<span className="inline-block">{option.label} </span>
									<span className="inline-block -ml-5 -indent-5">
										{option?._detail}
									</span>
								</div>
								{isSelected && !option?._detail && (
									<Icon
										type="Checkmark"
										classNames="selected-checkmark icon-white w-4"
									/>
								)}
							</div>
						);
					}}
				/>
				<Icon
					type="DownArrow"
					onClick={() => {
						if (disabled) return;
						setOpen(true);
						handleSearch();
					}}
					classNames={`select-down-arrow w-4    ${!open ? '' : 'active'}  ${
						!disabled ? 'cursor-pointer' : ''
					}`}
				/>
				{error.length ? <p className="error-msg -bottom-4">{error}</p> : ''}
			</div>
		</div>
	);
};

export default AsyncSelectField;
