import { useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { BASE_URL } from 'shared/constants/AppConst';

import AppSnackbar from '@healthme/core/MessageView';
import Modal from '@healthme/core/Modal/Index';
import purchaseAPI from '@healthme/services/api/purchases/Index';
import userService from '@healthme/services/user';
import { isNetworkManager } from '@healthme/utility/Utils';

import Button from '../Button/Index';
import Icon from '../Icon/Index';

const toWrapperClasses = 'flex w-full max-w-xs mt-2 space-x-3 to-message"';
const fromWrapperClasses =
	'flex justify-end w-full max-w-xs mt-2 ml-auto space-x-3 logged-in-user-message';
const fromBubbleClasses = 'p-3 text-white rounded-l-lg rounded-br-lg bg-green';
const toBubbleClasses = 'p-3 bg-gray-300 rounded-r-lg rounded-bl-lg';

function useInterval(callback, delay) {
	const intervalRef = React.useRef(null);
	const savedCallback = React.useRef(callback);
	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	React.useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === 'number') {
			intervalRef.current = window.setInterval(tick, delay);
			return () => window.clearInterval(intervalRef.current);
		}
	}, [delay]);
	return intervalRef;
}

export default function MessageChatModal({
	purchaseId,
	onClose,
	openModal,
	selectedNetwork = null,
	messageCount = 25,
}) {
	const user = userService.getUser();
	const [organization, setOrganization] = React.useState<any>({});
	const [patient, setPatient] = React.useState<any>({});
	const [marketplace, setMarketplace] = React.useState<any>({});
	const [claimmedPackage, setClaimedPackage] = React.useState<any>({});
	const [messages, setMessages] = React.useState([]);
	const messageContainer = React.useRef(null);
	const inputRef = React.useRef(null);
	const [title, setTitle] = React.useState('');
	const [isNetwork, setIsNetwork] = React.useState(isNetworkManager());
	const [perPage, setPerPage] = React.useState(messageCount === 0 ? 25 : messageCount);
	const hiddenFileInput: any = React.useRef(null);
	const [fileError, setFileError] = React.useState(null);
	const [files, setFiles] = React.useState([]);
	const [value, setValue] = React.useState();
	const [patientFiles, setPatientFiles] = React.useState([]);
	const [openRequestHelp, setOpenRequestHelp] = React.useState(false);
	const [openSnackbar, setOpenSnackBar] = React.useState(false);
	const [snackbarProops, setSnackbarProps] = React.useState<any>({
		variant: 'success',
		message: 'Help is on the way. A HealthMe Admin will join shortly',
	});
	React.useEffect(() => {
		if (!openModal) return;
		let purchasePath = `purchases/${purchaseId}`;

		const pageData$ = purchaseAPI.getRaw(purchasePath, { useCache: true });
		const messages$ = purchaseAPI.get(
			`/${purchaseId}/messages?order_by=+created_at&per_page=${perPage}`
		);

		Promise.all([pageData$, messages$]).then(
			([
				{ organization, patient_account, marketplace, claimed_package },
				{ data: messages, page_meta },
			]) => {
				setPatientFiles(files);
				setPerPage(() => page_meta.total);
				setOrganization(organization);
				setPatient(patient_account);
				setMarketplace(marketplace);
				setClaimedPackage(claimed_package);
				setMessages(
					messages.map(e => ({
						...e,
						attachments: [...(e?.attachments || [])].filter(Boolean),
					}))
				);
				console.log(selectedNetwork?.network_organization, selectedNetwork.name);
				if (isNetwork === 1) {
					setTitle(marketplace?.name);
				} else {
					setTitle(selectedNetwork?.network_organization || selectedNetwork.name);
				}
				messageContainer?.current?.scrollIntoView({
					behavior: 'instant',
				});
				inputRef?.current?.focus();
			}
		);
	}, [openModal]);

	useInterval(() => {
		if (!purchaseId) return;
		refreshMessages();
	}, 1000 * 10);
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - refreshMessages
	 **-------------------------------------------------------------------------------------
	 */
	const refreshMessages = () => {
		if (!purchaseId) return;
		purchaseAPI
			.get(`/${purchaseId}/messages?order_by=+created_at&per_page=${perPage}`)
			.then(messages => {
				setMessages(messages.data);
				setPerPage(() => messages.page_meta.total);
			});
		return;
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			message: '',
		},

		onSubmit: async (values: any) => {
			if (!values.message.length) return;
			let message = values.message;
			let errors = false;
			setFileError(null);
			if (files.length > 0) {
				message = message.replace(files[0].name, '').trim();
			}
			if (!message.length) return;
			if (inputRef.current.textContent) {
				inputRef.current.textContent = '';
			}
			setFiles([]);
			hiddenFileInput.current.value = '';
			let resp;
			if (files.length > 0) {
				try {
					resp = await purchaseAPI.postFileRaws(
						`purchases/${purchaseId}/upload_files`,
						files
					);
				} catch (e) {
					errors = true;
				}
			}
			if (errors) {
				setFileError(
					'There was an error uploading the file. Ensure your file is less than 10mb in size. Please try again.'
				);
				return;
			}
			purchaseAPI
				.post(
					{ text: message, attachments: files.length > 0 ? files.map(e => e.name) : [] },
					`/${purchaseId}/messages`
				)
				.then(() => {
					formik.resetForm();
					purchaseAPI
						.get(
							`/${purchaseId}/messages?order_by=+created_at&per_page=${perPage + 10}`
						)
						.then(({ data: messages, page_meta }) => {
							setMessages(messages);
							setPerPage(() => page_meta.total);
							messageContainer?.current?.scrollIntoView({ behavior: 'smooth' });
						});
				});
		},
	});
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		onClose();
		setMessages([]);
		setPerPage(25);
		setTitle('');
		setPatient({});
		setMarketplace({});
		setClaimedPackage({});
		setPatientFiles([]);
	};
	const handleClick = event => {
		hiddenFileInput?.current?.click();
	};
	const handleChange = event => {
		const fileUploaded = Object.keys(event.target.files).map(e => event.target.files[e].type);
		const allowedFiles = [
			'application/pdf',
			'image/png',
			'image/jpeg',
			'image/gif',
			'image/svg+xml',
		];
		if (!fileUploaded.every(e => allowedFiles.includes(e))) {
			setFileError('PDFs and Images only allowed.');
			return;
		}
		setFileError(null);
		setFiles([...files, ...Object.keys(event.target.files).map(e => event.target.files[e])]);
		formik.setFieldValue('message', `${event.target.files[0].name} ${formik.values.message}`);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - downloadFile
	 **-------------------------------------------------------------------------------------
	 */
	const downloadFile = file => {
		window.open(
			`${BASE_URL}/purchases/${purchaseId}/download_file?filename=${encodeURIComponent(
				file
			)}`,
			'_blank'
		);
		return;
		return;
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onMessageHelp
	 **-------------------------------------------------------------------------------------
	 */
	const onMessageHelp = async () => {
		try {
			await purchaseAPI.postRaw({}, `purchases/${purchaseId}/request_healthme_support`);
			setOpenRequestHelp(false);
			setSnackbarProps({
				variant: 'success',
				message: 'Help is on the way. A HealthMe Admin will join shortly',
			});
			setOpenSnackBar(true);
			const messages = await purchaseAPI.get(
				`/${purchaseId}/messages?order_by=+created_at&per_page=${perPage}`
			);
			setMessages(messages.data);
			setPerPage(messages.page_meta.total);

			refreshMessages();
		} catch (error) {
			setSnackbarProps({
				variant: 'error',
				message: 'There was an error requesting help. Please try again.',
			});
			setOpenSnackBar(true);
		}

		return;
	};

	return (
		<>
			<Modal padding="pt-6 px-0" maxWidth="sm" open={openModal} onClose={handleModalClose}>
				<div style={{ minHeight: '20vh' }} className="mt-2">
					<>
						<div className="border-b">
							<p className="mb-1 font-semibold text-center text-h5">
								Direct Message with {title}
							</p>
							<div className="flex justify-around my-2 font-medium">
								<p>
									Patient: {patient?.patient_first_name}{' '}
									{patient?.patient_last_name}
								</p>
								<p>Package: {claimmedPackage?.title}</p>
							</div>
						</div>
						<div
							className="flex flex-col flex-grow w-full overflow-hidden bg-white rounded-lg"
							style={{ minHeight: '50vh' }}
						>
							<p className="px-20 my-1 text-xs text-center">
								Welcome to CareNav messaging - your place for communication between
								providers, care navigators, and HealthMe Admins.
							</p>
							<div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
								{messages.map((message, idx) => {
									let wrapperClass = toWrapperClasses;
									let bubbleClass = toBubbleClasses;
									if (
										isNetwork === 1 &&
										message.staff_account?.network_organization_id
									) {
										wrapperClass = fromWrapperClasses;
										bubbleClass = fromBubbleClasses;
									}
									if (
										isNetwork === 1 &&
										!message.staff_account?.network_organization_id
									) {
										wrapperClass = toWrapperClasses;
										bubbleClass = toBubbleClasses;
									}

									if (
										isNetwork === 0 &&
										!message.staff_account?.network_organization_id
									) {
										wrapperClass = fromWrapperClasses;
										bubbleClass = fromBubbleClasses;
									}
									if (
										isNetwork === 0 &&
										message.staff_account?.network_organization_id
									) {
										wrapperClass = toWrapperClasses;
										bubbleClass = toBubbleClasses;
									}
									if (message?.staff_account?.role === 'network_manager') {
										message.label = `${message.staff_account.first_name} 
														${message.staff_account.last_name} at 
														${selectedNetwork?.network_organization || selectedNetwork.name}`;
									} else {
										message.label = `${message?.staff_account?.first_name} 
														${message?.staff_account?.last_name} at 
														${marketplace.name}`;
									}
									if (message?.staff_account?.role === 'admin') {
										bubbleClass += ' bg-blue-500 text-white';
										message.label = `${message.staff_account.first_name} 
														${message.staff_account.last_name} at HealthMe`;
									}
									if (message?.title === 'Automated Message') {
										bubbleClass += ' bg-blue-500 text-white';
										message.label = `Automated Message at HealthMe`;
									}

									return (
										<>
											<div
												key={`message-${message.id}`}
												className={wrapperClass}
											>
												<div className="min-w-0">
													<p className="truncate">{message.label}</p>
													<div className={bubbleClass}>
														<p className="text-sm">{message.text}</p>
													</div>

													{message?.attachments?.map(e => {
														return (
															<>
																<div
																	onClick={() => downloadFile(e)}
																	className="flex items-center my-2 gap-x-1"
																>
																	<Icon
																		type="Attachment"
																		classNames="w-5 h-5 icon-blue cursor-pointer"
																	/>
																	<span className="text-blue-500 cursor-pointer">
																		{e}
																	</span>
																</div>
															</>
														);
													})}

													<span className="text-xs leading-none text-gray-500">
														{moment(message.created_at).fromNow()}{' '}
														{message?.read_by && (
															<em>
																(Read By{' '}
																{message.read_by
																	.map(
																		e =>
																			`${
																				e.first_name
																			} ${e.last_name.charAt(
																				0
																			)}.`
																	)
																	.join(', ')}
																)
															</em>
														)}
													</span>
												</div>
											</div>
										</>
									);
								})}
								<div ref={messageContainer}></div>
							</div>

							<form onSubmit={formik.handleSubmit}>
								<p className="text-sm italic text-center text-red-500">
									{fileError}
								</p>
								<div className="flex p-4 bg-gray-50 gap-x-2 ">
									<div onClick={handleClick} className="flex justify-between">
										<input
											type="file"
											multiple
											ref={hiddenFileInput}
											onChange={handleChange}
											style={{ display: 'none' }}
										/>

										<Icon
											type="Attachment"
											classNames="w-7 h-7 mt-2 icon-blue cursor-pointer"
										/>
									</div>
									<div className="flex items-center w-full p-3 text-sm bg-white border rounded">
										{files.length > 0 && (
											<ChipDismissible
												onClear={() => {
													setFiles([]);
													hiddenFileInput.current.value = '';
												}}
												text={
													files.length === 1
														? files[0].name
														: `${files.length} files`
												}
											/>
										)}
										<div
											ref={inputRef}
											contentEditable
											suppressContentEditableWarning={true}
											className="flex items-center w-full text-sm bg-white rounded focus:outline-none"
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
													formik.handleSubmit();
												}
											}}
											onInput={(e: any) => {
												formik.setFieldValue('message', e.target.outerText);
											}}
										></div>
									</div>
									<button type="submit" className="font-semibold">
										Send
									</button>
								</div>
								<div className="pb-2 text-center bg-gray-50">
									<p>
										<a
											className="text-link"
											onClick={e => {
												e.preventDefault();
												setOpenRequestHelp(true);
											}}
										>
											Ask a HealthMe Admin to Join This Chat
										</a>
									</p>
								</div>
							</form>
						</div>
					</>
				</div>
			</Modal>
			<Modal
				open={openRequestHelp}
				onClose={() => setOpenRequestHelp(false)}
				maxWidth="sm"
				title="Request Help"
			>
				<div className="px-4 ">
					<p className="text-p">
						Need more assistance? Request a HealthMe Admin to join the conversation for
						further assistance.
					</p>
				</div>
				<div className="flex justify-end">
					<Button
						onClick={() => setOpenRequestHelp(false)}
						type="text-link"
						color="text-blue"
					>
						Cancel
					</Button>

					<Button
						disabled={!openRequestHelp}
						onClick={() => onMessageHelp()}
						type="primary"
						color="text-white"
					>
						Request Help
					</Button>
				</div>
			</Modal>
			<AppSnackbar
				open={openSnackbar}
				variant={snackbarProops.variant}
				message={snackbarProops.message}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => setOpenSnackBar(false)}
			/>
		</>
	);
}

export function ChipDismissible({ text = '', onClear }) {
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onDismiss
	 **-------------------------------------------------------------------------------------
	 */
	const onDismiss = () => {
		onClear();
		return;
	};

	return (
		<>
			<div
				data-dismissible="chip"
				className="relative flex select-none items-center whitespace-nowrap rounded-lg bg-gray-300 py-1.5 px-3 font-sans text-xs uppercase text-blue-500 mr-2"
			>
				<span className="mr-5">{text}</span>
				<button
					onClick={onDismiss}
					data-dismissible-target="chip"
					className=""
					type="button"
				>
					<span className="transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							className="w-4 h-4"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M6 18L18 6M6 6l12 12"
							></path>
						</svg>
					</span>
				</button>
			</div>
		</>
	);
}