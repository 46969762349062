import queryString from 'query-string';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { useHistory, useLocation } from 'react-router-dom';

import { setInitialPath } from '../../redux/actions';
import { AppState } from '../../redux/store';
import { INITIAL_PATIENT_URL, INITIAL_URL } from '../../shared/constants/AppConst';
import AppContextPropsType from '../../types/AppContextPropsType';
import { Loader } from '../index';
import AppContext from './AppContext';
import { useAuthToken } from './AppHooks';
import { checkPermission } from './Utils';

interface AuthRoutesProps {
	children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
	const { pathname, search } = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { routes } = useContext<AppContextPropsType>(AppContext);

	const [loading, user] = useAuthToken();
	const { initialPath } = useSelector<AppState, AppState['settings']>(({ settings }) => settings);
	const currentRoute = matchRoutes(routes, pathname)[0].route;
	let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);

	const handleRedirect = pathname => {
		const pathnameArr = pathname.split('/');
		const securedRoutes = ['auth', 'secure'];
		if (pathnameArr.length < 2) {
			return false;
		}
		const route = pathnameArr[1];

		if (securedRoutes.includes(route)) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		function setInitPath() {
			if (
				initialPath === '/' &&
				[
					'/auth/signin',
					'/auth/zendesk/sso',
					'/auth/signup',
					'/auth/confirm-signup',
					'/auth/reset-password',
					'/auth/mfa',
					'/error-pages/error-404',
					'/auth/forget-password',
				].indexOf(pathname) === -1
			) {
				if (isPermitted) {
					dispatch(setInitialPath(pathname));
				} else {
					dispatch(setInitialPath(undefined));
				}
			}
			if (user?.role == 'network_manager') dispatch(setInitialPath(undefined));
		}
		setInitPath();
	}, [dispatch, isPermitted, initialPath, pathname]);

	useEffect(() => {
		if (!loading) {
			if (!user && !isPermitted) {
				console.log('redirecting to signin');
				const qs = queryString.parse(search);
				let searchString = new URLSearchParams(qs).toString();
				const redirectUrl = encodeURIComponent(`redirectUrl=${pathname}?${searchString}`);
				history.push({
					pathname: '/auth/signin',
					search: redirectUrl,
				}); // allowed route
			} else if (user && !isPermitted) {
				history.push('/error-pages/error-404'); // Not found
			} else if (user && isPermitted) {
				if (
					pathname === '/' ||
					pathname === '/auth/signin' ||
					pathname === '/auth/signup' ||
					pathname === '/auth/mfa' ||
					pathname === '/auth/zendesk/sso'
				) {
					if (pathname === '/') history.push('/auth/signin');

					if (handleRedirect(pathname)) {
						const params = new URLSearchParams(search);
						const returnTo = params.get('return_to');
						if (returnTo) {
							history.push('/secure/release-notes');
							return;
						}

						if (user['role'] == 'network_manager') {
							history.push('/secure/networks/dashboard');
							return;
						}
						let route = user.isPatient ? INITIAL_PATIENT_URL : INITIAL_URL;
						history.push(route);
					}
				} else {
					// @ts-ignore
					if (
						initialPath &&
						INITIAL_URL !== initialPath &&
						initialPath !== '/'
						// initialPath !== '/signin' ||
						// initialPath !== '/signup')
					) {
						if (handleRedirect(pathname)) history.push(initialPath);
					}
				}
			}
			if (pathname === '/') history.push('/auth/signin');
		}
	}, [user, loading, initialPath, isPermitted, pathname, history]);

	return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
