import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import AppContextPropsType from 'types/AppContextPropsType';

import RadioField from '@healthme/core/Form/RadioField/Index';
import organizationAPI from '@healthme/services/api/organization/index';
import userService from '@healthme/services/user';
import AppContext from '@healthme/utility/AppContext';
import List from '@material-ui/core/List';

import routesConfig, { NavItemProps, routesNetworkConfig } from '../../../../modules/routesConfig';
import VerticalItem from './VerticalItem';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
	const queryClient = useQueryClient();
	const user = userService.getUser();
	const params: any = useParams();
	const [stepsCompleted, setStepsCompleted] = React.useState(0);

	const [org, setOrg] = React.useState({
		name: '',
		onboarding: {
			organization_verified: false,
			tsa_signed: false,
			primary_payee_verified: false,
			fee_payment_completed: false,
			marketplaces_verified: false,
			staff_accounts_verified: false,
			finalized: false,
		},
	});

	const { data } = useQuery(
		`organizations:${params.id}`,
		() => {
			if (!window.location.pathname.includes('onboarding') || user?.network_id)
				return Promise.resolve();
			return organizationAPI.get(
				`/${window.location.pathname.split('/').pop()}?inflate=true`
			);
		},
		{
			onSuccess: data => {
				if (!data) return;
				setOrg(data);
				const steps = Object.keys(data?.onboarding || []).filter(item => {
					return data.onboarding[item] === true;
				});
				setStepsCompleted(steps.length);
			},
		}
	);

	const { onBoardingStep, updateOnboardingStep } =
		React.useContext<AppContextPropsType>(AppContext);

	const isSelected = (step, type) => {
		if (data?.onboarding[type] === true) return 'true';
		if (onBoardingStep >= step) return 'true';
		return 'false';
	};

	const onStepClick = step => {
		if (updateOnboardingStep) updateOnboardingStep(step);
	};
	if (window.location.pathname.includes('onboarding')) {
		const logout = {
			id: 'Logout',
			title: 'Save & Logout',
			messageId: 'sidebar.secure.logout',
			type: 'logout',
			url: '/secure/logout',
			icon: 'Logout',
		};

		return (
			<>
				<div className="z-50 ml-3">
					<h1 className="mt-5 text-lg font-semibold text-black">{org.name} </h1>
					<h1 className="mt-5 mb-1 text-base text-black">Setup Progress</h1>
					<div className="relative pt-1 pr-5 mb-10">
						<div className="flex h-2 overflow-hidden text-xs bg-gray-200 rounded">
							<div
								style={{ width: `${(100 / 6) * stepsCompleted}%` }}
								className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
							></div>
						</div>
					</div>
					<div className="my-5">
						{data?.onboarding?.organization_verified}
						<RadioField
							value="true"
							selected={isSelected(1, 'organization_verified')}
							text="1. Verify Organization"
							onChange={() => onStepClick(1)}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							noIcon={
								data?.onboarding?.organization_verified === false ? true : false
							}
						/>
					</div>
					<div className="my-5">
						<RadioField
							value="true"
							selected={isSelected(2, 'tsa_signed')}
							text="2. Review & Sign TSA"
							onChange={() => onStepClick(2)}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							noIcon={data?.onboarding?.tsa_signed === false ? true : false}
						/>
					</div>
					<div className="my-5">
						<RadioField
							value="true"
							selected={isSelected(3, 'primary_payee_verified')}
							text="3. Payout Information"
							onChange={() => onStepClick(3)}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							noIcon={
								data?.onboarding?.primary_payee_verified === false ? true : false
							}
						/>
					</div>
					<div className="my-5">
						<RadioField
							value="true"
							selected={isSelected(4, 'onboarding_fee_payment_completed')}
							text="4. Payment Information"
							onChange={() => onStepClick(4)}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							noIcon={
								data?.onboarding?.onboarding_fee_payment_completed === false
									? true
									: false
							}
						/>
					</div>
					<div className="my-5">
						<RadioField
							value="true"
							selected={isSelected(5, 'marketplaces_verified')}
							text="5. Build Marketplace(s)"
							onChange={() =>
								data?.onboarding?.onboarding_fee_payment_completed && onStepClick(5)
							}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							disabled={data?.onboarding?.onboarding_fee_payment_completed === true}
							noIcon={
								data?.onboarding?.marketplaces_verified === false ? true : false
							}
						/>
					</div>
					<div className="my-5">
						<RadioField
							value="true"
							selected={isSelected(6, 'staff_accounts_verified')}
							text="6. Invite Team Members"
							onChange={() => onStepClick(6)}
							className="my-1 font-semibold cursor-pointer"
							iconColor="icon-lightgreen"
							noIcon={
								data?.onboarding?.staff_accounts_verified === false ? true : false
							}
						/>
					</div>
				</div>
				<div className="mt-32">
					<List>
						<VerticalItem item={logout} level={0} />
					</List>
				</div>
			</>
		);
	}
	// if (window.location.pathname.includes('patient')) {
	// 	return (
	// 		<List>
	// 			{patientRoutesConfig.map((item: NavItemProps) => (
	// 				<React.Fragment key={item.id}>
	// 					{<VerticalItem item={item} level={0} />}
	// 				</React.Fragment>
	// 			))}
	// 		</List>
	// 	);
	// }
	return (
		<List>
			{(user?.role !== 'network_manager' ? routesConfig : routesNetworkConfig).map(
				(item: NavItemProps) => {
					if (item?.adminOnly && user.role !== 'admin')
						return <span key={item.id}></span>;
					if (item?.auth && !item?.auth.includes(user.role))
						return <span key={item.id}></span>;
					return (
						<React.Fragment key={item.id}>
							{<VerticalItem item={item} level={0} />}
						</React.Fragment>
					);
				}
			)}
		</List>
	);
};

export default Navigation;
