import * as React from 'react';
import { useMutation } from 'react-query';

import Button from '@healthme/core/Button/Index';
import RadioField from '@healthme/core/Form/RadioField/Index';
import AppSnackbar from '@healthme/core/MessageView';
import Modal from '@healthme/core/Modal/Index';
import purchaseAPI from '@healthme/services/api/purchases/Index';

import { ErrorMessage } from '../ErrorMessage/Index';
import Icon from '../Icon/Index';

export interface IRefundRequestModalProps {
	openModal: boolean;
	payment: any;
	onClose: () => void;
	showPreviousStep?: boolean;
	endpoint?: string;
}

export function RefundRequestModal({
	openModal,
	payment,
	onClose,
	showPreviousStep,
	endpoint = 'purchases',
}: IRefundRequestModalProps) {
	//USE STATE
	const [refundError, setRefundError] = React.useState('');
	const [refundReason, setRefundReason] = React.useState('cancellation');
	const [successMessage, setSuccessMessage] = React.useState(false);
	const [snackbarProops, setSnackbarProps] = React.useState<any>({
		variant: 'success',
		message: 'Refund Successfully Requested.',
	});
	const [openSnackbar, setOpenSnackBar] = React.useState(false);

	//REACT QUERY
	const { mutate: mutateRefund, isLoading } = useMutation(
		item =>
			purchaseAPI.postRaw(
				{},
				`${endpoint}/${payment.id}/request_refund?reason=${refundReason}`
			),
		{
			onSuccess: () => {
				setOpenSnackBar(true);
				handleModalClose();
			},
			onError: (err: any) => {
				try {
					if (err?.data) {
						const error = JSON.parse(err.data);
						setRefundError(`${error?.error}, ${error?.detail}`);
					}
				} catch (e) {
					setRefundError('Server Error - Please contact HealthMe Support.');
				}
			},
		}
	);
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setRefundError('');
		onClose();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - sendRefund
	 **-------------------------------------------------------------------------------------
	 */
	const sendRefund = () => {
		mutateRefund();
	};
	return (
		<>
			<Modal maxWidth="sm" open={openModal} onClose={handleModalClose}>
				{showPreviousStep && (
					<a onClick={() => handleModalClose()} className="font-semibold text-link">
						<Icon type="LeftArrow" classNames="w-2 icon-blue inline-block ml-5 mr-2" />{' '}
						Previous Step
					</a>
				)}
				{refundError.length > 0 && <ErrorMessage message={refundError} />}

				<div className="">
					<p className="mb-1 font-semibold text-center text-h3">Request Refund</p>
					<p className="text-center text-p">
						Refund request will be processed in 1-2 days.
					</p>

					<div className="flex justify-between px-16 mt-5">
						<div className="">
							<RadioField
								value="cancellation"
								selected={refundReason}
								text="Cancellation"
								onChange={setRefundReason}
								className="my-2"
							/>
						</div>
						<div className="">
							<RadioField
								value="accidental_purchase"
								selected={refundReason}
								text="Accidental Purchase"
								onChange={setRefundReason}
								className="my-2"
							/>
						</div>
						<div className="">
							<RadioField
								value="other"
								selected={refundReason}
								text="Other"
								onChange={setRefundReason}
								className="my-2"
							/>
						</div>
					</div>
					<div className="flex flex-wrap justify-center mt-5">
						<div className="w-9/12">
							<Button
								disabled={isLoading}
								onClick={sendRefund}
								extraClasses="w-full max-w-full"
							>
								Submit Request
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<AppSnackbar
				open={openSnackbar}
				variant={snackbarProops.variant}
				message={snackbarProops.message}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => setOpenSnackBar(false)}
			/>
		</>
	);
}
