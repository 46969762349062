import { Dispatch } from 'redux';
import { BASE_URL } from 'shared/constants/AppConst';
import { AuthType } from 'shared/constants/AppEnums';
import { AppActions } from 'types';
import { SET_AUTH_TOKEN, SIGNOUT_AUTH_SUCCESS, UPDATE_AUTH_USER } from 'types/actions/Auth.actions';

import axios from '@healthme/services/api/utilities/Fetch';

import { fetchError, fetchStart, fetchSuccess } from './Common';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - onJwtUserSignUp
 **-------------------------------------------------------------------------------------
 */
export const onJwtUserSignUp = (body: { email: string; password: string; name: string }) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch(fetchStart());
		try {
			const res = await axios.post(`${BASE_URL}/users`, body);
			localStorage.setItem('token', res.data.token);
			dispatch(setJWTToken(res.data.token));
			await loadJWTUser(dispatch);
		} catch (err: any) {
			dispatch(fetchError(err.response.data.error));
		}
	};
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - onJwtSignIn
 **-------------------------------------------------------------------------------------
 */
export const onJwtSignIn = (body: { username: string; password: string; search?: string }) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			const res = await axios.post(`${BASE_URL}/auth/staff_accounts/login`, body);
			if (res?.mfa) {
				window.location.href = '/auth/mfa';
				return;
			}
			dispatch(fetchStart());
			localStorage.setItem('loggedIn', '1');
			const user = btoa(
				unescape(encodeURIComponent(JSON.stringify({ ...res.data, isPatient: false })))
			);
			localStorage.setItem('user', user);
			localStorage.setItem('role', res.data.role);
			if (body?.search) {
				const params = new URLSearchParams(body?.search);
				const returnTo = params.get('return_to');
				if (returnTo) {
					window.location.href = '/secure/release-notes';
					return;
				}
			}

			if (res.data?.network_id) {
				dispatch(setJWTToken(res.data.token));
				await loadJWTUser(dispatch);
				return;
			}

			if (res?.data?.organization?.id) {
				const res2 = await axios.get(
					`${BASE_URL}/organizations/${res.data.organization.id}?inflate=true`
				);
				if (
					res2?.data?.onboarding?.finalized !== undefined &&
					res.data.role !== 'admin' &&
					res2.data.onboarding.finalized === false
				) {
					if (!['organization_manager'].includes(res.data.role)) {
						localStorage.removeItem('token');
						window.location.href = `/auth/pause`;
						return;
					}
					localStorage.setItem('isAssumedOrganization', 'yes');
					localStorage.setItem('organization', res2.data.id);
					localStorage.setItem('organizationName', res2.data.name);
					window.location.href = `/secure/onboarding/${res2.data.id}`;
					return;
				}
			}

			const redirectUrl = decodeURIComponent(window.location.search);

			dispatch(setJWTToken(res.data.token));
			await loadJWTUser(dispatch);
			if (redirectUrl) {
				window.location.href = redirectUrl.replace('?redirectUrl=', '');
			}
		} catch (err: any) {
			console.log(err);
			if (err && err?.data) {
				dispatch(fetchError(err.data));
				return;
			}
			try {
				dispatch(
					fetchError(
						`You have entered an invalid email address or password. Please try again.`
					)
				);
			} catch (e) {}
		}
	};
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - onJwtSignIn
 **-------------------------------------------------------------------------------------
 */
export const onMFAJwtSignIn = res => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			dispatch(fetchStart());
			localStorage.setItem('loggedIn', '1');
			const user = btoa(
				unescape(encodeURIComponent(JSON.stringify({ ...res.data, isPatient: false })))
			);
			localStorage.setItem('user', user);
			localStorage.setItem('role', res.data.role);
			if (res.data?.network_id) {
				dispatch(setJWTToken(res.data.token));
				await loadJWTUser(dispatch);
				return;
			}
			const res2 = await axios.get(
				`${BASE_URL}/organizations/${res.data.organization.id}?inflate=true`
			);
			if (
				res2?.data?.onboarding?.finalized !== undefined &&
				res.data.role !== 'admin' &&
				res2.data.onboarding.finalized === false
			) {
				if (!['organization_manager'].includes(res.data.role)) {
					localStorage.removeItem('token');
					window.location.href = `/auth/pause`;
					return;
				}
				localStorage.setItem('isAssumedOrganization', 'yes');
				localStorage.setItem('organization', res2.data.id);
				localStorage.setItem('organizationName', res2.data.name);
				window.location.href = `/secure/onboarding/${res2.data.id}`;
				return;
			} else {
				dispatch(setJWTToken(user));
				await loadJWTUser(dispatch);
				//window.location.href = `/secure/dashboard`;
			}
		} catch (err) {
			try {
				dispatch(
					fetchError(
						`You have entered an invalid email address or password. Please try again.`
					)
				);
			} catch (e) {}
		}
	};
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - onJwtPatientSignIn
 **-------------------------------------------------------------------------------------
 */
export const onJwtPatientSignIn = (body: { username: string; password: string }) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			const res = await axios.post(`${BASE_URL}/auth/patient_accounts/login`, body);
			dispatch(fetchStart());
			localStorage.setItem(
				'user',
				btoa(JSON.stringify({ ...res.data, isPatient: true, role: 'patient' }))
			);
			localStorage.setItem('patient', 'true');
			dispatch(setJWTToken(res.data.token));
			await loadJWTUser(dispatch);
		} catch (err) {
			try {
				dispatch(
					fetchError(
						'The email or password entered is incorrect. Please try again and contact support@healthmedocs.com for additional assistance.'
					)
				);
			} catch (e) {}
		}
	};
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - loadJWTUser
 **-------------------------------------------------------------------------------------
 */
export const loadJWTUser = async (dispatch: Dispatch<AppActions>) => {
	dispatch(fetchStart());
	dispatch(fetchSuccess());
	const userData = JSON.parse(atob(localStorage.getItem('user') as string));
	const user = {
		authType: AuthType.JWT_AUTH,
		...userData,
	};
	dispatch({
		type: UPDATE_AUTH_USER,
		payload: getUserObject(user),
	});
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - setJWTToken
 **-------------------------------------------------------------------------------------
 */

export const setJWTToken = (token: string | null): AppActions => ({
	type: SET_AUTH_TOKEN,
	payload: token,
});
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - getUserObject
 **-------------------------------------------------------------------------------------
 */

const getUserObject = (authUser: any) => {
	const userData = JSON.parse(atob(localStorage.getItem('user') as string));

	return {
		...userData,
	};
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - onJWTAuthSignout
 **-------------------------------------------------------------------------------------
 */
export const onJWTAuthSignout = () => {
	return (dispatch: Dispatch<AppActions>) => {
		dispatch(fetchSuccess());
		setTimeout(() => {
			dispatch({ type: SIGNOUT_AUTH_SUCCESS });
			dispatch(fetchSuccess());
			localStorage.removeItem('token');
		}, 500);
	};
};
