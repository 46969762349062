import moment from 'moment';
import { useIntl } from 'react-intl';

import { ApiCore } from '@healthme/services/api/utilities/Core';
import userService from '@healthme/services/user';
import { useMediaQuery } from '@material-ui/core';
import { createMuiTheme, useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { HealthMeTheme } from '../../types/AppContextPropsType';

type BreakpointOrNull = Breakpoint | null;

const cache = new ApiCore({
	url: '',
});

export const isDev = window.location.hostname === 'localhost';
export const isLoggedIn = () => Number(localStorage.getItem('loggedIn'));
export const isNetworkManager = () => Number(localStorage.getItem('role') === 'network_manager');
export function capitalizeFirstLetter(string = '') {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getNetworkUrl
 **-------------------------------------------------------------------------------------
 */
export function getNetworkSlug() {
	if (
		window.location.hostname == 'network.healthmedocs.com' ||
		window.location.hostname == 'patients.healthmedocs.com' ||
		window.location.hostname == 'network.healthmedocs.local'
	) {
		return window.location.pathname.split('/')[1];
	}
	return window.location.pathname.split('/')[2];
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - formatPhone
 **-------------------------------------------------------------------------------------
 */
export function formatPhone(val) {
	val = val.replace(/ /gi, '');
	val = val.replace(/\(/gi, '');
	val = val.replace(/\)/gi, '');
	val = val.replace(/-/gi, '');
	return val;
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getNetworkUrl
 **-------------------------------------------------------------------------------------
 */
export function getNetworkUrl(path, prependUrl = false, _isLoggedIn = isNetworkManager()) {
	if (_isLoggedIn || localStorage.getItem('role') === 'network_manager') {
		return `/secure/networks${path}`;
	}

	if (
		window.location.hostname == 'network.healthmedocs.com' ||
		window.location.hostname == 'patients.healthmedocs.com' ||
		window.location.hostname == 'network.healthmedocs.local'
	) {
		return prependUrl ? 'https://network.healthmedocs.com/' + path : path;
	}

	if (window.location.hostname == 'stage.healthmedocs.com') {
		return prependUrl ? `https://stage.healthmedocs.com/n/${path}` : `/n${path}`;
	}

	if (window.location.hostname == 'd2av5rd2s484ze.cloudfront.net') {
		return prependUrl ? `https://d2av5rd2s484ze.cloudfront.net/n/${path}` : path;
	}

	return prependUrl ? `http://localhost:3000/n/${path}` : `/n${path}`;
}

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - errorParser
 **-------------------------------------------------------------------------------------
 */
export function errorParser(err) {
	try {
		const errorResp = JSON.parse(err.data);
		if (errorResp?.errors) {
			const errors = JSON.parse(err.data)
				.errors.map(item =>
					capitalizeFirstLetter(`${item.field.replace(/_/g, ' ')} ${item.reason}`)
				)
				.join(', ');
			return errors;
		}
		if (errorResp?.error) {
			const error = JSON.parse(err.data);
			return `${capitalizeFirstLetter(error.error)} ${capitalizeFirstLetter(error?.detail)}`;
		}
		return errorResp.detail;
	} catch (e) {
		return 'Internal Server Error, Please Try Again';
	}
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - sortArrayOfObjects
 **-------------------------------------------------------------------------------------
 */
export function sortArrayOfObjects(key, order = 'asc') {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			// property doesn't exist on either object
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === 'desc' ? comparison * -1 : comparison;
	};
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - removeItem
 **-------------------------------------------------------------------------------------
 */
export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
	const index = arr.indexOf(value);
	if (index > -1) {
		arr.splice(index, 1);
	}
	return arr;
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - dollarsToCents
 **-------------------------------------------------------------------------------------
 */ export const dollarsToCents = amount =>
	Math.round(100 * parseFloat(typeof amount === 'string' ? amount.replace(/[$,]/g, '') : amount));

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - centsToDollars
 **-------------------------------------------------------------------------------------
 */
export const centsToDollars = amount => (amount / 100).toFixed(2);
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isBreakPointDown
 **-------------------------------------------------------------------------------------
 */
export const isBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
	const defaultTheme = createMuiTheme();
	return defaultTheme.breakpoints.width(key) > window.innerWidth;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - useDownBreakPointChecker
 **-------------------------------------------------------------------------------------
 */
export const useDownBreakPointChecker = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
	return useMediaQuery((theme: HealthMeTheme) => theme.breakpoints.down(key));
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - useBreakPointDown
 **-------------------------------------------------------------------------------------
 */

export const useBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down(key));
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - useWidth
 **-------------------------------------------------------------------------------------
 */
export const useWidth = () => {
	const theme: HealthMeTheme = useTheme();
	const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, null) || 'xs'
	);
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - createRoutes
 **-------------------------------------------------------------------------------------
 */
export const createRoutes = (routeConfigs: any[]) => {
	let allRoutes: any[] = [];
	routeConfigs.forEach(config => {
		allRoutes = [...allRoutes, ...setRoutes(config)];
	});
	return allRoutes;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - setRoutes
 **-------------------------------------------------------------------------------------
 */
export const setRoutes = (config: any) => {
	let routes = [...config.routes];
	if (config.auth) {
		routes = routes.map(route => {
			let auth = route.auth ? [...config.auth, ...route.auth] : [...config.auth];
			return { ...route, auth };
		});
	}

	return [...routes];
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getBreakPointsValue
 **-------------------------------------------------------------------------------------
 */

export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
	if (typeof valueSet === 'number') return valueSet;
	switch (breakpoint) {
		case 'xs':
			return valueSet.xs;
		case 'sm':
			return valueSet.sm || valueSet.xs;
		case 'md':
			return valueSet.md || valueSet.sm || valueSet.xs;
		case 'lg':
			return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
		default:
			return valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
	}
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getFileSize
 **-------------------------------------------------------------------------------------
 */

export const getFileSize = (bytes: number) => {
	if (bytes === 0) return '0 Bytes';
	let k = 1024,
		dm = 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getCustomDateTime
 **-------------------------------------------------------------------------------------
 */

export const getCustomDateTime = (value = 0, unit = 'days', format = 'YYYY-MM-DD'): string => {
	if (value === 0) {
		return moment().format(format) as string;
	} else {
		// @ts-ignore
		return moment().add(value, unit).format(format) as string;
	}
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - timeFromNow
 **-------------------------------------------------------------------------------------
 */

export const timeFromNow = (date: string) => {
	const timestamp = +moment(date).format('X');
	const newDate = moment.unix(timestamp);
	return moment(newDate).fromNow();
};

// 'intl' service singleton reference
let intl: any;
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - IntlGlobalProvider
 **-------------------------------------------------------------------------------------
 */

export function IntlGlobalProvider({ children }: any) {
	intl = useIntl();
	// Keep the 'intl' service reference
	return children;
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - appIntl
 **-------------------------------------------------------------------------------------
 */

export const appIntl = () => {
	return intl;
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - checkPermission
 **-------------------------------------------------------------------------------------
 */

export const checkPermission = (
	routeAuth: any | null | undefined,
	userRole: any | null | undefined
) => {
	const isGFE = localStorage.getItem('hideGFE');
	if (routeAuth === null || routeAuth === undefined) {
		return true;
	}
	if (userRole && Array.isArray(userRole)) {
		return routeAuth.some((r: any) => userRole.indexOf(r) >= 0);
	}

	if (routeAuth.length === 0) {
		return !userRole || userRole.length === 0;
	}
	if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
		return routeAuth.some(r => userRole.indexOf(r) >= 0);
	}
	if (isGFE) {
		return routeAuth.indexOf('GFE') >= 0;
	}
	return routeAuth.indexOf(userRole) >= 0;
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME -
 **-------------------------------------------------------------------------------------
 */
export function cacheApi(apiCall, key): Promise<any> {
	const resp = sessionStorage.getItem(key);
	if (resp) {
		return Promise.resolve(JSON.parse(resp));
	}
	return new Promise((resolve, reject) => {
		apiCall
			.then(resp => {
				sessionStorage.setItem(key, JSON.stringify(resp));
				resolve(resp);
			})
			.catch(err => {
				reject(err);
			});
	});
}

export function cacheApi2(url, params, key = null): Promise<any> {
	const resp = sessionStorage.getItem(url);
	const production = isProduction();
	if (resp && production) {
		return Promise.resolve(JSON.parse(resp));
	}
	return new Promise((resolve, reject) => {
		cache
			.getRaw(url, params)
			.then(resp => {
				if (resp?.data) {
					if (resp.data.length > 0) {
						sessionStorage.setItem(url, JSON.stringify(resp));
						resolve(resp);
						return;
					}
					resolve(resp);
					return;
				}
				sessionStorage.setItem(url, JSON.stringify(resp));

				resolve(resp);
			})
			.catch(err => {
				reject(err);
			});
	});
}

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isObjectEmpty
 **-------------------------------------------------------------------------------------
 */
export const capitalize = s => {
	return s[0].toUpperCase() + s.slice(1);
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isObjectEmpty
 **-------------------------------------------------------------------------------------
 */
export const isJson = str => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isObjectEmpty
 **-------------------------------------------------------------------------------------
 */
export const isProduction = () => {
	if (
		window.location.hostname === 'patients.healthmedocs.com' ||
		window.location.hostname === 'carenav.healthmedocs.com' ||
		window.location.hostname === 'network.healthmedocs.com'
	) {
		return true;
	}
	return false;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isObjectEmpty
 **-------------------------------------------------------------------------------------
 */
export const isObjectEmpty = value => {
	return value && Object.keys(value).length === 0;
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isLocal
 **-------------------------------------------------------------------------------------
 */

export const isLocal = () => {
	if (window.location.hostname.includes('local')) {
		return true;
	}
	return false;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - waitFor
 **-------------------------------------------------------------------------------------
 */

export function waitFor(predicate, timeout) {
	return new Promise((resolve, reject) => {
		const check = () => {
			if (!predicate()) return;
			clearInterval(interval);
			resolve(true);
		};
		const interval = setInterval(check, 100);
		check();

		if (!timeout) return;
		setTimeout(() => {
			clearInterval(interval);
			reject();
		}, timeout);
	});
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - trimStringValues
 **-------------------------------------------------------------------------------------
 */

export function trimStringValues(obj) {
	for (let key in obj) {
		if (typeof obj[key] === 'string') {
			obj[key] = obj[key].trim();
		} else if (typeof obj[key] === 'object' && obj[key] !== null) {
			trimStringValues(obj[key]);
		}
	}
}
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - validateEmail
 **-------------------------------------------------------------------------------------
 */

export function validateEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getApiUrl
 **-------------------------------------------------------------------------------------
 */
export const getNetowrkApiUrl = path => {
	return !isPublicNetwork() ? path : `public/${path}`;
};
/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getNetworkOrgId
 **-------------------------------------------------------------------------------------
 */
export const getNetworkOrganizationId = () => {
	if (!isPublicNetwork()) {
		const user = userService.getUser();
		return user.network_organization_id;
	}
	const id = localStorage.getItem('networkOrganizationId');
	return id;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getNetworkId
 **-------------------------------------------------------------------------------------
 */
export const getNetworkId = (params, queryString) => {
	if (isPublicNetwork()) {
		return params.networkSlug;
	}

	return queryString?.networkId;
};

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - isPublicNetwork
 **-------------------------------------------------------------------------------------
 */
export const isPublicNetwork = () => {
	const networkSlug = window.location.pathname.split('/')[1];
	if (networkSlug === 'n' || window.location.hostname.includes('network.')) {
		return true;
	}
	return false;
};

export function isFirstCharacterALetter(str) {
	const regex = /^[a-zA-Z]/;
	return regex.test(str.charAt(0));
}

export function isFirstCharacterALetterAndNextFourAreNumbers(str) {
	const regex = /^[a-zA-Z]\d{4}/;
	return regex.test(str);
}

export function isNumber(value) {
	return typeof value === 'number' && !isNaN(value);
}
