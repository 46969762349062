import { useFormik } from 'formik';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import Button from '@healthme/core/Button/Index';
import Divider from '@healthme/core/Divider/Index';
import TextField from '@healthme/core/Form/TextField/Index';
import Modal from '@healthme/core/Modal/Index';
import purchaseAPI from '@healthme/services/api/purchases/Index';

import { errorParser } from '../../utility/Utils';
import { ErrorMessage } from '../ErrorMessage/Index';
import { RefundRequestModal } from '../RequestRefundModal/Index';
import { ResendCodeModal } from '../ResendCodeModal/Index';
import SuccessMessage from '../SuccessMessage/Index';

export interface IClaimPaymentModalProps {
	openModal: boolean;
	payment?: any;
	hideRefund?: boolean;
	onClose: () => void;
}

export function ClaimPaymentModal(props: IClaimPaymentModalProps) {
	const queryClient = useQueryClient();
	//USE STATE
	const [redeemError, setRedeemError] = React.useState('');
	const [hideModal, setHideModal] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [formValues, setFormValues] = React.useState<any>({});
	const [openRequestRefundModal, setOpenRequestRefundModal] = React.useState(false);
	const [openResendCodeModal, setOpenResendCodeModal] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const [alertText, setAlertText] = React.useState(
		'Has the patient been treated by the Provider?'
	);
	//REACT QUERY
	const {
		mutate: mutateRedeem,
		data: redeemResponse,
		isLoading,
	} = useMutation((code: any) =>
		purchaseAPI.post({}, `/redeem?redemption_code=${code.toLowerCase().replace(/-/gi, '')}`)
	);
	React.useEffect(() => {
		formik.setFieldValue('redemptionCode', props?.payment?.redemption_code || '');
	}, [props?.payment?.redemption_code]);
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			redemptionCode: '',
		},
		validationSchema: Yup.object({
			redemptionCode: Yup.string().required('Redemption Code required'),
		}),
		onSubmit: (values: any) => {
			setOpen(true);
			setFormValues(values);
			formik.resetForm();
		},
	});
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setRedeemError('');
		setOpenRequestRefundModal(false);
		formik.resetForm();
		props.onClose();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleRefundModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleRefundModalClose = () => {
		setOpenRequestRefundModal(false);
		setHideModal(false);
		formik.resetForm();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleResendModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleResendModalClose = () => {
		formik.resetForm();
		setOpenResendCodeModal(false);
		setHideModal(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleNoResponse
	 **-------------------------------------------------------------------------------------
	 */
	const handleNoResponse = () => {
		setAlertText('Patients must be seen before packages can be redeemed.');
		queryClient.fetchQuery('marketplacea');
		queryClient.fetchQuery('claimed_packages');
		queryClient.fetchQuery('analytics');
		// setOpen(false);
		// setRedeemError('');
		// setOpenRequestRefundModal(false);
		// setFormValues({});
		formik.resetForm();
		// props.onClose();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleYesResponse
	 **-------------------------------------------------------------------------------------
	 */
	const handleYesResponse = () => {
		setOpen(false);
		mutateRedeem(formValues.redemptionCode, {
			onError: (err: any) => {
				setRedeemError(errorParser(err));
			},
			onSuccess: resp => {
				setMessage('This package was successfully redeemed.');
				queryClient.fetchQuery('marketplacea');
				queryClient.fetchQuery('claimed_packages');
				queryClient.fetchQuery('analytics');

				setSuccessMessage(true);
				handleModalClose();
				formik.resetForm();
			},
		});
	};
	return (
		<>
			{!hideModal && (
				<>
					<Modal maxWidth="xs" open={props.openModal} onClose={handleModalClose}>
						{redeemError.length > 0 && (
							<div className="mt-5">
								<ErrorMessage message={redeemError} />
							</div>
						)}

						<form onSubmit={formik.handleSubmit}>
							<div className="">
								<p className="mb-1 font-semibold text-center text-h3">
									Claim Payment
								</p>
								<p className="w-8/12 m-auto text-center text-p">
									Patient will provide a 9-digit code from email after purchase.
								</p>
								<div className="flex flex-wrap justify-center mt-5">
									<div className="w-9/12">
										<TextField
											label="9-Digit Redemption Code"
											placeholder="XXXXXXXX"
											onChange={formik.handleChange}
											value={formik.values.redemptionCode}
											error={formik.errors.redemptionCode}
											name="redemptionCode"
											className="w-full"
											showMask
											mask="***-***-***"
										/>
									</div>
								</div>
								<div className="flex flex-wrap justify-center mt-0">
									<div className="w-9/12">
										<Button
											buttonType="submit"
											disabled={isLoading}
											type="primary"
											color="text-white"
											extraClasses="w-full max-w-full"
										>
											Claim Payment
										</Button>
									</div>
								</div>

								{!props.hideRefund && (
									<div className="flex flex-wrap justify-center mt-5 text-p">
										or &nbsp;
										<a
											onClick={() => {
												setOpenRequestRefundModal(true);
												setHideModal(true);
											}}
											className="text-link"
										>
											Request Refund
										</a>
									</div>
								)}
								{props.payment && (
									<>
										<Divider />
										<p className="w-8/12 m-auto font-semibold text-center text-p">
											Patient has not received code?
										</p>
										<div className="flex flex-wrap justify-center mt-5">
											<div className="w-9/12">
												<Button
													type="tertiary"
													color="text-blue"
													extraClasses="w-full max-w-full"
													onClick={() => {
														setOpenResendCodeModal(true);
														setHideModal(true);
													}}
												>
													Code Lookup &amp; Resend
												</Button>
											</div>
										</div>
									</>
								)}
							</div>
						</form>
					</Modal>
					<Modal maxWidth="xs" open={open} onClose={() => setOpen(false)}>
						<p className="mb-1 font-semibold text-center text-h3">Alert</p>
						<p className="w-8/12 m-auto mb-5 font-medium text-center text-p">
							{alertText}
						</p>
						<div className="flex flex-wrap justify-center gap-6 mt-0">
							<div className="w-5/12">
								<Button
									type="tertiary"
									color="text-blue"
									extraClasses="w-full max-w-full"
									onClick={handleNoResponse}
								>
									No
								</Button>
							</div>
							<div className="w-5/12">
								<Button
									type="primary"
									color="text-white"
									extraClasses="w-full max-w-full"
									onClick={handleYesResponse}
								>
									Yes
								</Button>
							</div>
						</div>
					</Modal>
					{successMessage && (
						<SuccessMessage
							message={message}
							open={successMessage}
							onClose={setSuccessMessage}
						/>
					)}
				</>
			)}

			{!props.hideRefund && (
				<RefundRequestModal
					openModal={openRequestRefundModal}
					onClose={handleRefundModalClose}
					showPreviousStep={true}
					payment={props.payment}
				/>
			)}

			{props.payment && (
				<ResendCodeModal
					openModal={openResendCodeModal}
					onClose={handleResendModalClose}
					showPreviousStep={true}
					payment={props.payment}
				/>
			)}
		</>
	);
}
