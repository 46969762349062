import React from 'react';

export const securePagesConfig = [
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/onboarding/:id',
				component: React.lazy(() => import('./Pages/OnBoarding/Index')),
			},
		],
	},
	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/onboard-tracker',
				component: React.lazy(() => import('./Pages/OnboardTracker/Index')),
			},
		],
	},
	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/message-center',
				component: React.lazy(() => import('./Pages/MessageCenter/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
			'network_manager',
		],
		routes: [
			{
				path: '/secure/release-notes',
				component: React.lazy(() => import('./Pages/ReleaseNotes/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
			'network_manager',
		],
		routes: [
			{
				path: '/secure/not-allowed',
				component: React.lazy(() => import('./Pages/NotAllowed')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/dashboard',
				component: React.lazy(() => import('./Pages/Dashboard/Index')),
			},
		],
	},
	//networks
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/dashboard',
				component: React.lazy(() => import('./Pages/Networks/Dashboard')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/marketplace',
				component: React.lazy(() => import('./Pages/Networks/Dashboard')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/payments',
				component: React.lazy(() => import('./Pages/Networks/Payments')),
			},
		],
	},
	{
		auth: ['network_manager', 'admin'],
		routes: [
			{
				path: '/secure/networks/accounts/group/:id',
				component: React.lazy(() => import('./Pages/Networks/Accounts/GroupForm')),
			},
		],
	},
	{
		auth: ['network_manager', 'admin'],
		routes: [
			{
				path: '/secure/networks/accounts/staff/:id',
				component: React.lazy(() => import('./Pages/Networks/Accounts/StaffEdit')),
			},
		],
	},

	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/accounts/patient/:patientId',
				component: React.lazy(() => import('./Pages/Accounts/Patient/Index')),
			},
		],
	},

	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/accounts',
				component: React.lazy(() => import('./Pages/Networks/Accounts')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/settings',
				component: React.lazy(() => import('./Pages/Networks/Settings')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/resources',
				component: React.lazy(() => import('./Pages/Networks/Resources')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'network_manager',
		],
		routes: [
			{
				path: '/secure/networks/:id/staff/:staffId/:isNetwork',
				component: React.lazy(() => import('./Pages/Accounts/Staff/Index')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkSlug/checkout',
				component: React.lazy(() => import('./Pages/Networks/Checkout')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkSlug/:packageId/checkout',
				component: React.lazy(() => import('./Pages/Networks/Checkout')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkSlug/order-complete',
				component: React.lazy(() => import('./Pages/Networks/OrderComplete')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkSlug/:packageId/providers',
				component: React.lazy(() => import('./Pages/Networks/Providers')),
			},
		],
	},

	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkSlug/:packageId',
				component: React.lazy(() => import('./Pages/Networks/Package')),
			},
		],
	},
	{
		auth: ['network_manager'],
		routes: [
			{
				path: '/secure/networks/:networkId',
				component: React.lazy(() => import('./Pages/Networks/Dashboard')),
			},
		],
	},

	//networks
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/payments',
				component: React.lazy(() => import('./Pages/Payments/Index')),
			},
		],
	},

	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/subscriptions',
				component: React.lazy(() => import('./Pages/Subscriptions/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/reports',
				component: React.lazy(() => import('./Pages/Reports/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/analytics',
				component: React.lazy(() => import('./Pages/Analytics/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'network_manager',
		],
		routes: [
			{
				path: '/secure/patients/list',
				component: React.lazy(() => import('./Pages/Accounts/Patients/List')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'network_manager',
		],
		routes: [
			{
				path: '/secure/patients/view/:patientId',
				component: React.lazy(() => import('./Pages/Accounts/Patients/View')),
			},
		],
	},

	{
		auth: ['patient'],
		routes: [
			{
				path: '/secure/patient/dashboard',
				component: React.lazy(() => import('./Pages/PatientDashboard/Index')),
			},
		],
	},
	{
		auth: ['patient'],
		routes: [
			{
				path: '/secure/patient/purchases',
				component: React.lazy(() => import('./Pages/PatientPurchases/Index')),
			},
		],
	},
	{
		auth: ['patient'],
		routes: [
			{
				path: '/secure/patient/settings',
				component: React.lazy(() => import('./Pages/PatientSettings/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/network-orgs/:id/staff/:staffId',
				component: React.lazy(() => import('./Pages/Accounts/NetwokOrgs/StaffEdit')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/network-orgs/:id',
				component: React.lazy(() => import('./Pages/Accounts/NetwokOrgs/Index')),
			},
		],
	},

	{
		auth: ['service_provider', 'admin', 'organization_manager', 'marketplace_manager'],
		routes: [
			{
				path: '/secure/accounts/organization/:id',
				component: React.lazy(
					() => import('./Pages/Accounts/OrganizationList/OrganizationForm/Index')
				),
			},
		],
	},
	{
		auth: ['admin', 'network_manager', 'organization_manager', 'marketplace_manager'],
		routes: [
			{
				path: '/secure/accounts/networks/:id',
				component: React.lazy(() => import('./Pages/Accounts/Networks/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/:id/provider/:providerId',
				component: React.lazy(() => import('./Pages/Accounts/Provider/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/:id/staff/:staffId',
				component: React.lazy(() => import('./Pages/Accounts/Staff/Index')),
			},
		],
	},

	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/:id/p/:patientId',
				component: React.lazy(() => import('./Pages/Accounts/Patient/Index')),
			},
		],
	},

	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts/:id',
				component: React.lazy(() => import('./Pages/Accounts/Account/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
		],
		routes: [
			{
				path: '/secure/accounts',
				component: React.lazy(() => import('./Pages/Accounts/Index')),
			},
		],
	},
	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/packages/create',
				component: React.lazy(() => import('./Pages/Packages/Create')),
			},
		],
	},

	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/packages/edit/:id',
				component: React.lazy(() => import('./Pages/Packages/Create')),
			},
		],
	},

	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/packages/sort/type',
				component: React.lazy(() => import('./Pages/Packages/Sort/Type')),
			},
		],
	},
	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/packages/sort/package',
				component: React.lazy(() => import('./Pages/Packages/Sort/Package')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/packages/package/:id/edit',
				component: React.lazy(() => import('./Pages/Packages/ClaimedPackage/Edit')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/packages/package/:id',
				component: React.lazy(() => import('./Pages/Packages/ClaimedPackage/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/packages/claim/:id',
				component: React.lazy(() => import('./Pages/Packages/ClaimPackageEdit/Index')),
			},
		],
	},
	{
		auth: ['admin'],
		routes: [
			{
				path: '/secure/packages/create',
				component: React.lazy(() => import('./Pages/Packages/Create')),
			},
		],
	},

	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/packages',
				component: React.lazy(() => import('./Pages/Packages/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/resources',
				component: React.lazy(() => import('./Pages/Resources/Index')),
			},
		],
	},

	{
		auth: ['service_provider', 'admin', 'organization_manager', 'marketplace_manager', 'GFE'],
		routes: [
			{
				path: '/secure/settings',
				component: React.lazy(() => import('./Pages/Settings/Index')),
			},
		],
	},

	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure/gfe',
				component: React.lazy(() => import('./Pages/GFE/Index')),
			},
		],
	},
	{
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
		routes: [
			{
				path: '/secure',
				component: React.lazy(() => import('./Pages/Dashboard/Index')),
			},
		],
	},
];
