import { useFormik } from 'formik';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BASE_APP_URL, PHONE_REGEX_FN } from 'shared/constants/AppConst';
import * as Yup from 'yup';

import Button from '@healthme/core/Button/Index';
import { ErrorMessage } from '@healthme/core/ErrorMessage/Index';
import PhoneField from '@healthme/core/Form/PhoneField/Index';
import SelectField from '@healthme/core/Form/SelectField/Index';
import TextField from '@healthme/core/Form/TextField/Index';
import Icon from '@healthme/core/Icon/Index';
import SuccessMessage from '@healthme/core/SuccessMessage/Index';
import claimedPackagesAPI from '@healthme/services/api/claimed_packages/Index';
import { centsToDollars } from '@healthme/utility/Utils';

import AsyncSelectField from '../Form/SelectField/Asnyc';

export interface ISharePackageProps {
	isModal?: boolean;
	noBorder?: boolean;
	marketplaceId?: string;
}

export function SharePackage(props: ISharePackageProps) {
	const [type, setType] = React.useState('sms');
	const [shareLink, setShareLink] = React.useState('');
	const [packageId, setPackageId] = React.useState('');
	const [marketplaceId, setMarketplaceId] = React.useState(props.marketplaceId || '');
	const [isLoading, setIsLoading] = React.useState(false);
	const [formError, setFormError] = React.useState('');
	const [successMessage, setSuccessMessage] = React.useState(false);
	const [successCopyMessage, setSuccessCopyMessage] = React.useState(false);
	const [packagePath, setPackagePath] = React.useState('');
	const [serviceProvider, setServiceProvider] = React.useState('');
	const [ehrId, setEhrId] = React.useState('');
	const [providers, setProviders] = React.useState([]);

	const [clear, setClear] = React.useState(0);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - smsFormik
	 **-------------------------------------------------------------------------------------
	 */
	const smsFormik = useFormik({
		validateOnChange: true,
		initialValues: {
			phone: '',
			ehr_patient_id: '',
		},
		validationSchema: Yup.object({
			phone: Yup.string()
				.test(
					'phone-validation',
					'Phone number is not valid (xxx) xxx-xxxx',
					PHONE_REGEX_FN
				)
				.required(),
		}),
		onSubmit: async values => {
			setIsLoading(true);
			claimedPackagesAPI
				.postRaw(
					{},
					`claimed_packages/${packageId}/notifications/send_detail_link?method=sms&send_to=${encodeURIComponent(
						`${values.phone}`
					)}${
						serviceProvider.length > 0 ? `&service_provider_id=${serviceProvider}` : ``
					}${values?.ehr_patient_id ? `&ehr_patient_id=${values?.ehr_patient_id}` : ``}`
				)
				.then(resp => {
					setIsLoading(false);
					setSuccessMessage(true);
					setFormError('');
					smsFormik.resetForm();
				})
				.catch(err => {
					setIsLoading(false);
					setFormError('Error sending message, try again.');
				});
		},
	});
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - emailFormik
	 **-------------------------------------------------------------------------------------
	 */
	const emailFormik = useFormik({
		validateOnChange: true,
		initialValues: {
			email: '',
			ehr_patient_id: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().email().required(),
		}),
		onSubmit: async values => {
			claimedPackagesAPI
				.postRaw(
					{},
					`claimed_packages/${packageId}/notifications/send_detail_link?method=email&send_to=${
						values.email
					}${
						serviceProvider.length > 0 ? `&service_provider_id=${serviceProvider}` : ``
					}${values?.ehr_patient_id ? `&ehr_patient_id=${values?.ehr_patient_id}` : ``}`
				)
				.then(resp => {
					setIsLoading(false);
					setSuccessMessage(true);
					setFormError('');
					emailFormik.resetForm();
				})
				.catch(err => {
					setIsLoading(false);
					setFormError('Error sending message, try again.');
				});
		},
	});

	return (
		<div
			className={`relative h-full ${!props.isModal && ''} relative ${
				props.isModal && 'w-full'
			}`}
		>
			<div
				className={`bg-white p-4 h-full ${
					!props.noBorder && 'border rounded-md border-gray-300 shadow-sm'
				} relative ${props.isModal && 'mt-5'}`}
			>
				{!props.isModal && <h3 className="font-semibold text-h3">Send Link To Patient</h3>}
				{!props.isModal && (
					<Icon
						type="Link"
						classNames="w-8 icon-blue absolute right-6 top-5 opacity-60"
					/>
				)}
				{props.isModal && <p className="text-2xl font-bold text-center">Share Link</p>}
				{formError.length > 0 && <ErrorMessage message={formError} />}
				<SuccessMessage
					message="Message Successfully Sent."
					open={successMessage}
					onClose={setSuccessMessage}
				/>

				<hr />
				<div className="mt-5">
					<AsyncSelectField
						title="Select Marketplace"
						name="marketplaceId"
						selectedValue={''}
						term="name"
						path="marketplaces"
						formatter={item => ({
							value: item.id,
							label: item.name,
							slug: item.name_slug,
							organization_id: item.organization_id,
						})}
						onChange={e => {
							if (!e) {
								setMarketplaceId('');
								setSuccessCopyMessage(false);
								return;
							}
							setClear(new Date().getTime());
							setMarketplaceId(e);
							localStorage.setItem('filterMarketPlaceId', e);
							setPackagePath(``);
						}}
					/>
					<AsyncSelectField
						title="Select Package"
						name="packageId"
						term="title"
						selectedValue={''}
						path={`claimed_packages`}
						extraParams={`&active=true&inflate=true&marketplace_id[eq]=${marketplaceId}`}
						disabled={marketplaceId.length == 0}
						alterSelectedLabel={item => {
							return `${item.label} - $${centsToDollars(
								item.listing_price_public_app
							)}`;
						}}
						formatter={item => {
							return {
								...item,
								value: item.id,
								label: `${item.title}`,
								_detail: `$${centsToDollars(item.listing_price_public_app)}`,
							};
						}}
						clear={clear}
						onChange={(e, resp) => {
							if (!e) {
								setPackageId('');
								setSuccessCopyMessage(false);
								return;
							}
							setPackageId(e);
							setShareLink(
								`${
									window.location.hostname == 'localhost'
										? 'http://localhost:3000'
										: BASE_APP_URL
								}/${resp.organization.name_slug}/${resp.marketplace?.name_slug}/${
									resp.id
								}`
							);
							setPackagePath(
								`claimed_packages?marketplace_id[eq]=${resp.marketplace.id}&active=true&inflate=true`
							);
							setProviders(
								(resp.service_providers || []).map(item => ({
									value: item.id,
									label: `${item.last_name}, ${item.first_name}`,
								}))
							);
						}}
					/>
					<>
						<div className="flex flex-wrap justify-between w-8/12 m-auto my-5">
							<div
								onClick={() => setType('sms')}
								className={`flex items-center justify-center w-12 h-12 border rounded-full ${
									type === 'sms' ? 'bg-blue-500' : 'bg-blue-900'
								}`}
							>
								<Icon type="SMS" classNames="w-4 icon-white" />
							</div>
							<div
								onClick={() => setType('email')}
								className={`flex items-center justify-center w-12 h-12  border rounded-full ${
									type === 'email' ? 'bg-blue-500' : 'bg-blue-900'
								}`}
							>
								<Icon type="Envelope" classNames="w-4 icon-white" />
							</div>
							<div
								onClick={() => setType('copy')}
								className={`flex items-center justify-center w-12 h-12 border rounded-full ${
									type === 'copy' ? 'bg-blue-500' : 'bg-blue-900'
								}`}
							>
								<Icon type="Link" classNames="w-4 icon-white" />
							</div>
						</div>
						{type === 'copy' && (
							<div className="flex flex-col justify-center w-11/12 m-auto my-5 to-">
								<p className="mb-5 text-base font-semibold">Copy/Paste Link</p>
								<TextField
									name="email"
									placeholder=" "
									onChange={() => {}}
									label="Copy/Paste Link"
									value={`${shareLink}${
										serviceProvider.length ? `?spid=${serviceProvider}` : ''
									}`}
									className="w-full"
									disabled={packageId.length === 0}
								/>
								{/* <TextField
									name="ehr_patient_id"
									placeholder=" "
									onChange={(e: any) => {
										setEhrId(e?.target?.value);
									}}
									label="Patient EHR ID (Optional)"
									value={ehrId}
									className="w-full"
									disabled={packageId.length === 0}
								/> */}
								<SelectField
									list={providers}
									value={''}
									title="Select Provider (Optional)"
									name="serviceProviderId"
									onChange={e => {
										if (!e) {
											setServiceProvider('');
											return;
										}
										setServiceProvider(e);
									}}
								/>
								<CopyToClipboard
									text={`${shareLink}${
										serviceProvider.length
											? `?spid=${serviceProvider}${
													ehrId ? `&ehr_patient_id=${ehrId}` : ``
											  }`
											: ''
									}`}
								>
									<Button
										onClick={() => {
											setSuccessCopyMessage(true);
											setTimeout(() => setSuccessCopyMessage(false), 1500);
										}}
										width="w-full"
										disabled={packageId.length === 0}
										hideLoader={packageId.length === 0}
									>
										Copy Link
									</Button>
								</CopyToClipboard>

								{successCopyMessage && (
									<p className="italic text-center">Link copied to clipboard</p>
								)}
							</div>
						)}

						{type === 'sms' && (
							<form onSubmit={smsFormik.handleSubmit}>
								<div className="flex flex-col justify-center w-11/12 m-auto my-5">
									<p className="mb-5 text-base font-semibold">SMS Text</p>
									<PhoneField
										name="phone"
										onChange={e => smsFormik.setFieldValue('phone', `${e}`)}
										value={smsFormik.values.phone}
										error={smsFormik.errors.phone}
										placeholder="Recipient Phone Number"
										label="Recipient Phone Number"
										className="w-full"
										disabled={packageId.length === 0}
									/>
									{/* <TextField
										name="ehr_patient_id"
										placeholder=" "
										onChange={smsFormik.handleChange}
										label="Patient EHR ID (Optional)"
										value={smsFormik.values.ehr_patient_id}
										className="w-full"
										disabled={packageId.length === 0}
									/> */}

									<SelectField
										list={providers}
										value={''}
										title="Select Provider (Optional)"
										name="serviceProviderId"
										onChange={e => {
											if (!e) {
												setServiceProvider('');
												return;
											}
											setServiceProvider(e);
										}}
									/>

									<Button
										disabled={isLoading || packageId.length === 0}
										hideLoader={packageId.length === 0}
										buttonType="submit"
										width="w-full"
									>
										Send SMS Link
									</Button>
								</div>
							</form>
						)}

						{type === 'email' && (
							<form onSubmit={emailFormik.handleSubmit}>
								<div className="flex flex-col justify-center w-11/12 m-auto my-5">
									<p className="mb-5 text-base font-semibold">Email</p>
									<TextField
										name="email"
										onChange={emailFormik.handleChange}
										value={emailFormik.values.email}
										error={emailFormik.errors.email}
										placeholder=" "
										label="Recipient Email Address"
										className="w-full"
										disabled={packageId.length === 0}
									/>
									{/* <TextField
										name="ehr_patient_id"
										placeholder=" "
										onChange={emailFormik.handleChange}
										label="Patient EHR ID (Optional)"
										value={emailFormik.values.ehr_patient_id}
										className="w-full"
										disabled={packageId.length === 0}
									/> */}

									<SelectField
										list={providers}
										value={''}
										title="Select Provider (Optional)"
										name="serviceProviderId"
										onChange={e => {
											if (!e) {
												setServiceProvider('');
												return;
											}
											setServiceProvider(e);
										}}
									/>
									<Button
										disabled={isLoading || packageId.length === 0}
										buttonType="submit"
										hideLoader={packageId.length === 0}
										width="w-full"
									>
										Send Email
									</Button>
								</div>
							</form>
						)}
					</>
				</div>
			</div>
		</div>
	);
}
