import axios from 'axios';
import { BASE_URL } from 'shared/constants/AppConst';

import { handleError, handleResponse } from './Response';

export const appAxios = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});

appAxios.interceptors.request.use(
	config => {
		let headers = config.headers;
		const dontSendHeaders = config.headers['dontSendHeaders'];
		config.headers = {
			...headers,
			...getHeaders().headers,
		};
		if (dontSendHeaders) {
			delete config.headers['Assume-Organization'];
			delete config.headers['Assume-Role'];
			delete config.headers['Assume-Network-Organization'];
			delete config.headers['dontSendHeaders'];
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

appAxios.interceptors.response.use(
	res => res,
	async err => {
		const isLoggedIn = localStorage.getItem('loggedIn');
		if (err?.status === 401 && err?.data) {
			let json: any = {};
			if (typeof err?.data === 'string') {
				json = JSON.parse(err.data);
			} else {
				json = err.data;
			}
			if (
				json.error === 'session_not_found' ||
				(json?.detail &&
					json.detail.toLowerCase() ===
						'your session is invalid. please log in to continue.')
			) {
				localStorage.clear();
				window.location.href = '/auth/signin';
				return;
			}
		}
		if (err?.status === 403 && isLoggedIn) {
			window.location.href = '/secure/not-allowed';
			return;
		}
		if (err?.data?.errors) {
			try {
				return Promise.reject(JSON.parse(err.data));
			} catch (e) {
				return '500 Server Error. Please contact Healthme Support';
			}
		}
		return Promise.reject(err);
	}
);

/*
 **-------------------------------------------------------------------------------------
 ** FN NAME - getToken
 **-------------------------------------------------------------------------------------
 */
const getHeaders = () => {
	const headers = {};
	const organization = localStorage.getItem('organization');
	const isLoggedIn = localStorage.getItem('loggedIn');
	const assumeRole = localStorage.getItem('assumeRole');
	const networkOrganization = localStorage.getItem('networkOrg');
	if (organization && isLoggedIn) {
		headers['Assume-Organization'] = `${organization}`;
	}
	if (assumeRole && isLoggedIn) {
		headers['Assume-Role'] = `${assumeRole}`;
	}

	if (isLoggedIn && networkOrganization) {
		headers['Assume-Network-Organization'] = `${networkOrganization}`;
	}

	return {
		headers: { ...headers },
	};
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - getAll
 **-------------------------------------------------------------------------------------
 */
const get = (resource: string, sendHeaders: boolean = true): Promise<any> => {
	if (resource.endsWith('?')) {
		resource = resource.replace(/.$/, '');
	}
	return appAxios
		.get(`${BASE_URL}/${resource}`, sendHeaders ? getHeaders() : {})
		.then(handleResponse)
		.catch(handleError);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - getAll
 **-------------------------------------------------------------------------------------
 */
const getRaw = (resource: string): Promise<any> => {
	return appAxios
		.get(`${BASE_URL}/${resource}`, getHeaders())
		.then(handleResponse)
		.catch(handleError);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - getAll
 **-------------------------------------------------------------------------------------
 */
const getAll = (resource: string): Promise<any> => {
	return appAxios
		.get(`${BASE_URL}/${resource}`, getHeaders())
		.then(handleResponse)
		.catch(handleError);
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - getSingle
 **-------------------------------------------------------------------------------------
 */
const getSingle = (resource: string, id: any, params?: any) => {
	return appAxios
		.get(`${BASE_URL}/${resource}/${id}?${params}`, getHeaders())
		.then(handleResponse)
		.catch(handleError);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - post
 **-------------------------------------------------------------------------------------
 */
const post = (resource: string, model: any) => {
	return appAxios.post(`${BASE_URL}/${resource}`, model, getHeaders()).then(handleResponse);
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - post
 **-------------------------------------------------------------------------------------
 */
const postRaw = (resource: string, model: any) => {
	return appAxios.post(`${BASE_URL}/${resource}`, model, getHeaders());
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - post
 **-------------------------------------------------------------------------------------
 */
const patchRaw = (resource: string, model: any) => {
	return appAxios.patch(`${BASE_URL}/${resource}`, model, getHeaders());
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - post
 **-------------------------------------------------------------------------------------
 */
const deleteRaw = (resource: string) => {
	return appAxios.delete(`${BASE_URL}/${resource}`, getHeaders());
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - postFile
 **-------------------------------------------------------------------------------------
 */
const postFile = (resource: string, file: any) => {
	const headers = getHeaders();
	const data: FormData = new FormData();
	data.append('file', file);
	return appAxios
		.post(`${BASE_URL}/${resource}`, data, {
			headers: {
				...headers.headers,
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(handleResponse);
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - postFile
 **-------------------------------------------------------------------------------------
 */
const postFiles = (resource: string, files: any) => {
	const headers = getHeaders();
	const data: FormData = new FormData();
	files.forEach(file => {
		data.append('file[]', file);
	});

	return appAxios
		.post(`${BASE_URL}/${resource}`, data, {
			headers: {
				...headers.headers,
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(handleResponse);
};
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - get
 **-------------------------------------------------------------------------------------
 */
const put = (resource: string, model: any) => {
	return appAxios
		.put(`${BASE_URL}/${resource}`, model, getHeaders())
		.then(handleResponse)
		.catch(handleError);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - patch
 **-------------------------------------------------------------------------------------
 */
const patch = (resource: string, model: any, sendHeaders: boolean = true) => {
	return appAxios
		.patch(
			`${BASE_URL}/${resource}`,
			model,
			sendHeaders
				? getHeaders()
				: {
						headers: {
							dontSendHeaders: true,
						},
				  }
		)
		.then(handleResponse);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - remove
 **-------------------------------------------------------------------------------------
 */
const remove = (resource: string, id: number) => {
	return appAxios
		.delete(`${BASE_URL}/${resource}/${id}`, getHeaders())
		.then(handleResponse)
		.catch(handleError);
};

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - delete
 **-------------------------------------------------------------------------------------
 */
const deleteRecord = (resource: string) => {
	return appAxios.delete(`${BASE_URL}/${resource}`, getHeaders()).then(handleResponse);
};

export const apiProvider = {
	getAll,
	getSingle,
	post,
	put,
	patch,
	remove,
	getHeaders,
	postFile,
	get,
	deleteRecord,
	postRaw,
	patchRaw,
	deleteRaw,
	postFiles,
};
