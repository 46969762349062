import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleNavCollapsed } from 'redux/actions';
import AppLogo from 'shared/components/AppLogo';
import CarenavRequestDialog from 'shared/components/CarenavRequestDialog/Index';
import ClaimPaymentDialog from 'shared/components/ClaimPaymentDialog/Index';
import OrganizationChangeDialog from 'shared/components/OrganizationChangeDialog/Index';
import PatientLookUpDialog from 'shared/components/PatientLookUpDialog/Index';
import ShareLinkDialog from 'shared/components/ShareLinkDialog/Index';

import Icon from '@healthme/core/Icon/Index';
import userService from '@healthme/services/user';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Alert from '@material-ui/lab/Alert';

import HeaderMessages from '../../HeaderMessages';
import LanguageSwitcher from '../../LanguageSwitcher';
import useStyles from './AppHeader.style';
import Notifications from './Notifications';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
	const user: any = userService.getUser();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showAlert, setShowAlert] = React.useState(true);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleMobileMenuClose() {
		setMobileMoreAnchorEl(null);
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - showAssume
	 **-------------------------------------------------------------------------------------
	 */
	const showAssume = () => {
		if (localStorage.getItem('isAssumedOrganization')) return true;
		if (['service_provider', 'admin'].includes(user.role)) return true;

		return false;
	};

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem className={classes.menuItemRoot}>
				<HeaderMessages />
			</MenuItem>
			<MenuItem className={classes.menuItemRoot}>
				<Notifications />
			</MenuItem>
			<LanguageSwitcher />
		</Menu>
	);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - showMenu
	 **-------------------------------------------------------------------------------------
	 */
	const showMenu = () => {
		return (
			!window.location.pathname.includes('patient') &&
			!window.location.pathname.includes('onboarding')
		);
	};

	return (
		<>
			<AppBar className="border-b " color="inherit" elevation={0}>
				<Toolbar
					className={classes.appToolbar + `flex flex-wrap justify-between px-5 relative`}
					disableGutters={true}
				>
					<div className="flex">
						<Hidden lgUp>
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								onClick={() => dispatch(toggleNavCollapsed())}
							>
								<MenuIcon className={classes.menuIcon} />
							</IconButton>
						</Hidden>
						<AppLogo />
						{showAlert && window.location.hostname === 'demo.healthmedocs.com' ? (
							<div className="absolute transform -translate-x-1/2 left-1/2 z-90 top-20">
								<Alert onClose={() => setShowAlert(false)} severity="warning">
									<p className="whitespace-nowrap">
										THIS IS A PUBLIC DEMO. PLEASE DO NOT INCLUDE PATIENT HEALTH
										INFORMATION OR SENSITIVE INFORMATION.
									</p>
								</Alert>
							</div>
						) : null}
					</div>
					{user.role !== 'network_manager' && (
						<>
							<div className="hidden lt-md:block">
								<Icon
									type="Menu"
									classNames={'w-6 cursor-pointer'}
									aria-expanded={open ? 'true' : undefined}
									onClick={handleClick}
								/>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									{!window.location.pathname.includes('onboarding') && (
										<div className="p-1">
											{user.role !== 'admin' && <ShareLinkDialog />}
											{showAssume() && <OrganizationChangeDialog />}
											<PatientLookUpDialog />
											<ClaimPaymentDialog />
										</div>
									)}
								</Menu>
							</div>{' '}
							<div className="flex justify-end lt-md:hidden xs:hidden">
								{!window.location.pathname.includes('onboarding') && (
									<>
										<Notifications />
										{user.role !== 'admin' && <ShareLinkDialog />}
										{showAssume() && <OrganizationChangeDialog />}
										<PatientLookUpDialog />
										<ClaimPaymentDialog />
									</>
								)}
							</div>
						</>
					)}
					{user.role === 'network_manager' && (
						<>
							<div className="hidden lt-md:block">
								<Icon
									type="Menu"
									classNames={'w-6 cursor-pointer'}
									aria-expanded={open ? 'true' : undefined}
									onClick={handleClick}
								/>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<div className="p-1">
										<CarenavRequestDialog />
										<PatientLookUpDialog />
									</div>
								</Menu>
							</div>{' '}
							<div className="flex justify-end lt-md:hidden xs:hidden">
								<Notifications />
								{showAssume() && <OrganizationChangeDialog />}
								<CarenavRequestDialog />
								<PatientLookUpDialog />
							</div>
						</>
					)}
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</>
	);
};
export default AppHeader;
